import * as React from 'react'
import { useEffect } from 'react';
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain } from '@components/index'
import { FeedInFunc } from '@func/index'

const PlanPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainPlan'>
      {/* CONTENTS */}

      <ComMain
        ttl="PLAN"
        subttl="間取り"
        caption="image photo"
      >
        <StaticImage
          src="../assets/images/plan/main.jpg"
          alt=""
          className="_pc"
        />
        <StaticImage
          src="../assets/images/plan/main_sp.jpg"
          alt=""
          className="_sp"
        />
      </ComMain>


      <section className='sec-plan-list'>
        <div className="c-content">

          <div className="plan-list-wrap">
            <div className="link-box">

              <Link to="/plan/b-type/" className='comFeed'>
                <StaticImage
                  src="../assets/images/type/b_type_btn.png"
                  alt=""
                />
              </Link>

              <Link to="/plan/e-type/" className='comFeed'>
              <StaticImage
                  src="../assets/images/type/e_type_btn.png"
                  alt=""
                />
              </Link>

            </div>
          </div>

        </div>
      </section>


      <section className='sec-customize'>
        <div className="c-content">

          <div className="customize-wrap comFeed">
            <div className="left-box">
              <p className="ttl">CUSTOMIZE</p>
              <p className="lead">自分好みの空間にカスタマイズ「アイセルコ」</p>
              <p className="txt">「アイセルコ」とは、キッチンをはじめとする設備アイテムのグレードアップやライフスタイルに合わせた仕様変更を、住宅ローンに組み込み可能な工事中に行うアップグレードオプション。多彩なオプションメニューで、ディテールまでこだわったカスタマイズを個別対応する専門スタッフと共に楽しめます。あなたのこだわりやお好み、ご要望をぜひお聞かせください。</p>

              <div className="merit-box">
                <p className="merit-ttl">アイセルコ4つのメリット</p>
                <div className="merit-flex">
                  <div className="merit merit-1"><p>専門スタッフが<br />マンションギャラリーにて対応</p></div>
                  <div className="merit merit-2"><p>豊富な<br />グレードアップメニュー</p></div>
                  <div className="merit merit-3"><p>追加費用も<br />ローン組み入れ可能</p></div>
                  <div className="merit merit-4"><p>入居後のアフターサービスも<br />ご安心ください</p></div>
                </div>
              </div>
            </div>

            <div className="right-box">
              <div className="flex-box flex-box-1">
                <div className="img-box img-box-1">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/plan/customize-1.jpg"
                      alt=""
                    />
                  </div>
                  <p className="c-cap">参考写真</p>
                </div>
                <div className="img-box img-box-2">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/plan/customize-2.jpg"
                      alt=""
                    />
                  </div>
                  <p className="c-cap">参考写真</p>
                </div>
                <div className="img-box img-box-3">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/plan/customize-3.jpg"
                      alt=""
                    />
                  </div>
                  <p className="c-cap">参考写真</p>
                </div>
              </div>
              <div className="flex-box flex-box-2">
                <div className="img-box img-box-4">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/plan/customize-4.jpg"
                      alt=""
                    />
                  </div>
                  <p className="c-cap">参考写真</p>
                </div>
              </div>
            </div>
          </div>

          <div className="note-wrap">
            <p className="note">※専門スタッフ対応のため、ご予約が必要となります。お申込みには期限がございます。詳細は係員にお尋ねください。</p>
          </div>


        </div>
      </section>


      <div className="c-content">
        <div className="com-note-wrap">
          <p className="c-note">※掲載の間取り図は計画段階の図面を基に描き起こしたものであり、実際とは異なります。また今後、施工上の理由等により計画に変更が生じる場合があります。家具、植栽、造作家具、調度品等は販売価格には含まれません。<br />※「参考写真」の表記がある写真は、当社他物件や既存施設・設備及びメーカー提供の参考写真であり、形状・仕様・色等は実際とは異なる場合がありますので、予めご了承ください。<br />※「image photo」の表記がある写真は全てイメージです。</p>
        </div>
      </div>





      {/* CONTENTS */}
    </main>
  )
}

export default PlanPage
